var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-set-main" }, [
    _c("div", { staticClass: "box" }, [
      _c("i", {
        staticClass: "fa fa-user-circle-o",
        attrs: { "aria-hidden": "true" }
      }),
      _c("div", { staticClass: "row", staticStyle: { "margin-top": "20px" } }, [
        _c("div", { staticClass: "label" }, [_vm._v("用户名：")]),
        _c(
          "div",
          { staticClass: "inp-box" },
          [
            _c("el-input", {
              staticClass: "inp",
              attrs: { placeholder: "请输入内容", disabled: "" },
              model: {
                value: _vm.phone,
                callback: function($$v) {
                  _vm.phone = $$v
                },
                expression: "phone"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "label" }, [_vm._v("登录密码：")]),
        _c(
          "div",
          { staticClass: "inp-box" },
          [
            _c("el-input", {
              staticClass: "inp",
              attrs: {
                type: "password",
                placeholder: "请输入内容",
                disabled: ""
              },
              model: {
                value: _vm.password,
                callback: function($$v) {
                  _vm.password = $$v
                },
                expression: "password"
              }
            }),
            _c(
              "el-button",
              {
                staticClass: "edit",
                attrs: { type: "text" },
                on: { click: _vm.onEditPassword }
              },
              [_vm._v("修改")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }