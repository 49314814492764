<template>
  <div class="user-set-main">
    <div class="box">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      <div class="row" style="margin-top: 20px">
        <div class="label">用户名：</div>
        <div class="inp-box">
          <el-input class="inp" v-model="phone" placeholder="请输入内容" disabled/>
        </div>
      </div>
      <div class="row">
        <div class="label">登录密码：</div>
        <div class="inp-box">
          <el-input class="inp" v-model="password" type="password" placeholder="请输入内容" disabled/>
          <el-button class="edit" type="text" @click="onEditPassword">修改</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: '',
      password: '••••••••'
    };
  },
  activated () {
    this.phone = this.cache.getLS("userInfo")["account"];
    console.log("user-set-main created!!");
  },
  methods: {
      onEditPassword(){
          this.$router.push("/main/userSet/password");
      }
  }
};
</script>
<style lang="scss">
.user-set-main {
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -150px;
    width: 300px;
    height: 500px;
    text-align: center;

    .fa-user-circle-o {
      font-size: 160px;
      color: #666;
    }
  }

  .row {
    position: relative;
    height: 50px;
    line-height: 50px;

    .label {
      position: absolute;
      top: 0;
      left: -80px;
      width: 80px;
      font-size: 14px;
      color: #666;
      text-align: right;
    }

    .inp-box {
      position: relative;
      height: 100%;

      .el-input__inner {
        padding-right: 50px;
      }

      .edit {
        position: absolute;
        top: 5px;
        right: 8px;
      }
    }
  }
}
</style>


